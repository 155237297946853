import React from 'react';
import './popup.scss';

const Popup = ({ isOpen, onClose, isLarge, children }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className={`popup-container ${isLarge ? 'popup-container-large' : ''}`}>
        <button className="popup-close" onClick={onClose}>×</button>
        <div className="popup-content">
          {children}
        </div>
        {isLarge ? (
          <div className="large-close-button" onClick={onClose}>
            Close
          </div>
        ) : <></>}
      </div>
    </div>
  );
};

export default Popup;
