import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import moment from 'moment';
import MuxPlayer from '@mux/mux-player-react';
import Popup from '../../../components/generic/popup';
import Button from '../../../components/generic/button';
import { BURNETT_API_ENDPOINT } from '../../../config';

import './list-competitions.scss';

const CompetitionList = () => {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [competitionData, setCompetitionData] = useState([]);
  const [introPopupActive, setIntroPopupActive] = useState(false);

  const toggleIntroPopup = () => {
    setIntroPopupActive(!introPopupActive);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM Do, YYYY');
  };  

  const didMount = async () => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
  
      const getCompetitionData = await Axios.get(`${BURNETT_API_ENDPOINT}/get-competitions`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });
    
      const { data: resData } = getCompetitionData;
      const { data } = resData;
  
      setCompetitionData(data || []);

      if (data.length === 0) {
        setIntroPopupActive(true);
      }

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    didMount();
  }, []);

  const handleViewClick = (competitionId) => {
    navigate(`/competition/${competitionId}`);
  };

  const handleEditClick = (competitionId) => {
    navigate(`/edit/${competitionId}`);
  };

  const handleInviteClick = (competitionId) => {
    navigate(`/invite/${competitionId}`);
  };

  const handleManageSubmissionClick = (competitionId) => {
    navigate(`/competition/${competitionId}/manage-submission`);
  };

  const findUserTypeByEmail = (users, email) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) {
        return users[i].type;
      }
    }
    return null;
  }

  return (
    <>
      {competitionData && competitionData.length === 0 ? (
        <p style={{ maxWidth: 500, textAlign: 'left', margin: 0, marginBottom: 20 }}>
          You have not entered or have not been invited to any competitions at this time. 
          Please check back here at a later date for new competitions or invites from your event administrator.
        </p>
      ) : <></>}
      <ul className="list-competitions">

        {introPopupActive ? (
          <Popup
            isOpen={introPopupActive}
            onClose={toggleIntroPopup}
            isLarge
          >
            <MuxPlayer
              playbackId="dyLs006LTUH6GOK3kh01fmJB6oGf01shPQloQLpuGCtJh8"
              streamType="on-demand"
              accentColor="#0090F2"
              poster="https://longjump-public-assets.s3.us-east-1.amazonaws.com/intro-video-thumbnail.png"
            />
          </Popup>
        ) : <></>}

        {competitionData && competitionData.length > 0 && competitionData.map(competition => {

          const { 
            competition_id,
            competition_name,
            creator,
            competition_start_date,
            competition_deadline_date,
            competition_end_date,
            status,
            users
          } = competition;

          const { email } = user;

          let permissionScope = findUserTypeByEmail(users, email);
          
          return (
            <li key={competition_id} className="competition-item">
              <div className="permission-scope">{permissionScope.replace('_HISTORY', '')}</div>
              <h3>{competition_name}</h3>
              <span>{formatDate(competition_start_date)} — {formatDate(competition_end_date)}</span>
              <span>Submission Deadline: {formatDate(competition_deadline_date ? competition_deadline_date : competition_end_date)}</span>
              {/* <span>{status}</span> */}
              <div className="button-container">
                <Button text="View" onClick={()=> handleViewClick(competition_id)} className="primary" />
                {permissionScope === "OWNER" ||
                permissionScope === "ADMIN" ? (
                  <>
                    <Button text="Edit" onClick={()=> handleEditClick(competition_id)} className="secondary" />
                    <Button text="Invite" onClick={()=> handleInviteClick(competition_id)} className="secondary" />
                  </>
                ) : <></>}

                {permissionScope === "COMPETITOR" ? (
                  <>
                    <Button text="My Submissions" onClick={()=> handleManageSubmissionClick(competition_id)} className="secondary" />
                  </>
                ) : <></>}
              </div>
            </li>
          )})}
        </ul>
    </>
  );
};

export default CompetitionList;
