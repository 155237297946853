import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import UserCardSmall from '../../generic/userCardSmall';
import Popup from '../../../components/generic/popup';
import Button from '../../../components/generic/button';
import LoadingDots from '../../generic/loadingDots';
import { BURNETT_API_ENDPOINT } from '../../../config';
import './list-submissions-filter-search.scss';

function ListSubmissionsFilterSearch({ competitionData, competitionId, isCompetePage, competitionEndDate }) {
  const navigate = useNavigate();
  const { user, getIdTokenClaims } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submissionList, setSubmissionList] = useState([]);
  const [boostPopupActive, setBoostPopupActive] = useState(false);
  const [isBoostLoading, setIsBoostLoading] = useState(false);
  const [boostMetricsLoading, setBoostMetricsLoading] = useState(false);
  const [boostAllowed, setBoostAllowed] = useState(true);
  const [totalBoostPoints, setTotalBoostPoints] = useState(0);
  const [userBoostPointsRemaining, setUserBoostPointsRemaining] = useState(0);
  const [submissionBoostAmount, setSubmissionBoostAmount] = useState(0);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState();
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (competitionEndDate) {
      const now = new Date();
      const endDate = new Date(competitionEndDate);
  
      if (now > endDate) {
        setBoostAllowed(false);
      }
    }
  }, [competitionEndDate]);

  const fetchPublishedSubmissions = async () => {
    setSubmissionList(null);

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-published-competition-submissions?competition_id=${competitionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const resData = response.data;
      const { data } = resData;

      // Sort the submissions by total_points in descending order
      const sortedData = data.sort((a, b) => (b.total_points || 0) - (a.total_points || 0));
      setSubmissionList(sortedData);

      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!competitionId) return;
    fetchPublishedSubmissions();
  }, [competitionId]);

  const toggleBoostClick = async (submissionId) => {
    if (isBoostLoading) return;
    setSelectedSubmissionId(submissionId);
    setBoostPopupActive(!boostPopupActive);
  }

  const handleBoostInputChange = (e) => {
    setSubmissionBoostAmount(e.target.value);
  };

  const fetchUserBoostMetrics = async () => {
    setBoostMetricsLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-user-boost-metrics?competition_id=${competitionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const userBoostMetrics = response.data;

      const { allocations, max_allocation, total_spend } = userBoostMetrics;

      const pointsRemaining = max_allocation - total_spend;

      setUserBoostPointsRemaining(`${pointsRemaining} of ${max_allocation}`);

      for (const allocation of allocations) {
        if (allocation.submission_id === selectedSubmissionId) {
          setSubmissionBoostAmount(allocation.allocated_boost_points);
        }
      }

      setBoostMetricsLoading(false);

    } catch (err) {
      setBoostMetricsLoading(false);
    }
  };

  useEffect(() => {
    if (!boostPopupActive) return;
    fetchUserBoostMetrics();
  }, [boostPopupActive]);

  const confirmBoost = async () => {
    if (isBoostLoading || boostMetricsLoading) return;
    setIsBoostLoading(true);

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const boostParams = {
        competition_id: competitionId,
        submission_id: selectedSubmissionId,
        boost_points: submissionBoostAmount
      }

      const response = await axios.post(`${BURNETT_API_ENDPOINT}/create-submission-boost`, boostParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      toast.success('Submission Boosted!');
      setIsBoostLoading(false);
      setSubmissionBoostAmount("-")
      toggleBoostClick();
      fetchPublishedSubmissions();

    } catch (err) {
      setError(err.message);
      setIsBoostLoading(false);
      toast.error('Error boosting submission');
    }
  }

  useEffect(() => {
    if (!competitionData) return;

    const aggregatedUsers = competitionData.reduce((acc, item) => {
      if (item.users) {
        return [...acc, ...item.users];
      }
      return acc;
    }, []);

    setAllUsers(aggregatedUsers);
  }, [competitionData]);

  return (
    <div
      className="list-submissions-results-container"
      style={ isCompetePage ? { border: "unset", backgroundColor: "unset"} : {}}
    >
      
      {boostPopupActive && (
        <Popup isOpen={boostPopupActive} onClose={toggleBoostClick}>
          <h2>Boost Submission</h2>
          <p><b>{userBoostPointsRemaining}</b> points remaining for this competition</p>
          <div className="boost-popup-content">
            <div className="boost-popup-icon">
              <span>Number of points to boost</span>
            </div>
            <input
              type="number"
              name="boost"
              value={submissionBoostAmount}
              placeholder="0"
              onChange={(e) => handleBoostInputChange(e)}
              min="0"
            />
            <Button text={isBoostLoading ? "Loading" : "Boost!"} onClick={()=> confirmBoost()} className="primary" />
          </div>
        </Popup>
      )}
      
      {!isCompetePage ? <h3>Team Submissions</h3> : <></>}

      {!loading && !isBoostLoading && submissionList && submissionList.length > 0 ? (
        <ul>
          {submissionList.map((submission, index) => (
            <li 
              key={index} 
              style={{ cursor: 'pointer' }}
            >
              {index < 3 && submission.total_points !== 0 && (
                <div className={`ribben-icon ${index === 0 ? 'first-place' : index === 1 ? 'second-place' : 'third-place'}`}></div>
              )}
              <div
                className={`submission-name ${index > 2 ? 'mobile-submission-name' : '' }`}
                onClick={() => navigate(`/competition/${competitionId}/submission/${submission.submission_id}`)}
              >{submission.submission_name}</div>
              <div className="submission-users">
                {submission.submission_team.length > 0 && submission.submission_team.map((userEmail, index) => {
                  const matchingUser = allUsers.find(user => user.email === userEmail);
                  const userPathId = matchingUser ? matchingUser.user_id : "no-profile";
                  return <UserCardSmall key={index} competitionId={competitionId} userId={userPathId} />
                })}
              </div>
              <div className={`submission-points ${index > 2 ? 'mobile-submission-points' : '' }`}>{submission.total_points || 0} <span>pts</span></div>
              {boostAllowed ? (
                <div className="boost-button" onClick={() => toggleBoostClick(submission.submission_id)} />
              ) : <></>}
            </li>
          ))}
        </ul>
      ) : (
        <div>{!submissionList ? <LoadingDots /> : "No submissions yet"}</div>
      )}
    </div>
  );
}

export default ListSubmissionsFilterSearch;
