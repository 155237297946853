import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import MuxPlayer from '@mux/mux-player-react';
import axios from 'axios';
import moment from 'moment';
import { toast } from "react-toastify";
import ListSubmissions from '../../components/competition/listSubmissionsFilterSearch';
import ListVotingPanel from '../../components/competition/listVotingPanel';
import CompetitionStatus from '../../components/competition/competitionStatus';
import Button from '../../components/generic/button';
import BackButton from '../../components/generic/backButton';
import LoadingDots from '../../components/generic/loadingDots';
import { BURNETT_API_ENDPOINT } from '../../config';
import './competition.scss';

const Competition = () => {
  const { competitionId } = useParams();
  const navigate = useNavigate();
  const { user, getIdTokenClaims } = useAuth0();
  const [competitionRules, setCompetitionRules] = useState("");
  const [videoName, setVideoName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [creator, setCreator] = useState("");
  const [status, setStatus] = useState("");
  const [muxPlaybackId, setMuxPlaybackId] = useState(null);
  const [competitionStartDate, setCompetitionStartDate] = useState("");
  const [videoType, setVideoType] = useState("");
  const [competitionPrizeList, setCompetitionPrizeList] = useState([]);
  const [competitionEndDate, setCompetitionEndDate] = useState("");
  const [competitionDeadlineDate, setCompetitionDeadlineDate] = useState("");
  const [competitionName, setCompetitionName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [competitionDescription, setCompetitionDescription] = useState("");
  const [type, setType] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userSessionScope, setUserSessionScope] = useState("");
  const [competitionData, setCompetitionData] = useState([]);
  const [sponsors, setSponsors] = useState(null);
  const [isGuestUser, setIsGuestUser] = useState(false);

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM Do, YYYY');
  };

  const findUserTypeByEmail = (users = [], email = "") => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) {
        return users[i].type;
      }
    }
    return null;
  }

  useEffect(() => {
    const fetchCompetitionData = async () => {
      try {
        const claims = await getIdTokenClaims();
        const idToken = claims.__raw;
        const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition?competition_id=${competitionId}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json"
          }
        });

        const data = response.data;
        setCompetitionData([data]);
        setCompetitionRules(data.competition_rules);
        setVideoName(data.video_name);
        setCreatedAt(data.created_at);
        setCreator(data.creator);
        setStatus(data.status);
        setCompetitionStartDate(data.competition_start_date);
        setVideoType(data.video_type);
        setCompetitionPrizeList(data.competition_prize_list);
        setCompetitionEndDate(data.competition_end_date);
        setCompetitionDeadlineDate(data.competition_deadline_date);
        setCompetitionName(data.competition_name);
        setUpdatedAt(data.updated_at);
        setCompetitionDescription(data.competition_description);
        setType(data.type);
        setVideoUrl(data.video_url);
        setUsers(data.users);
        setMuxPlaybackId(data.mux_playback_id);
        setIsGuestUser(data.is_guest_user);
        setSponsors(data.competition_sponsors || null);

        const userSessionEmail = user.email;

        const permissionScope = findUserTypeByEmail(data.users, userSessionEmail);
        setUserSessionScope(permissionScope);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCompetitionData();
  }, [competitionId, getIdTokenClaims]);

  const onClickCreateSubmission = () => {
    navigate(`/competition/${competitionId}/manage-submission`);
  };

  const onClickLeaderboard = () => {
    navigate(`/compete?competition_id=${competitionId}`);
  };

  const copyToClipboard = () => {
    const link = window.location.href + "?redirect=true";
    navigator.clipboard.writeText(link).then(() => {
      toast.success("Competition link copied!");
    }).catch(err => {
      toast.error("Failed to copy the link.");
    });
  };

  if (loading) return <LoadingDots />;
  if (error) return <p>Invited guests and competitors only. Please check with the event organizer for access.</p>;

  return (
    status !== "PUBLISHED" && userSessionScope !== "OWNER" && userSessionScope !== "ADMIN" ? (
      <div className="competition-container">
        <h1>Competition coming soon...</h1>
        <span>This competiton is not yet published</span>
      </div>
    ) : (
    <div className="competition-container">
      <BackButton path={'/'}/>
      <br />
      {muxPlaybackId ? (
        <MuxPlayer
          playbackId={muxPlaybackId}
          streamType="on-demand"
          accentColor="#0090F2"
        />
      ) : <video width="100%" height="100%" controls>
      <source src={videoUrl} type={videoType} />
      Your browser does not support the video tag.
    </video> }

      <h1>{competitionName}</h1>
      <span><b>{formatDate(competitionStartDate)} </b>to <b>{formatDate(competitionEndDate)}</b></span>
      <br />
      <CompetitionStatus startDate={competitionStartDate} endDate={competitionEndDate} deadline={competitionDeadlineDate} />

      <div className="competition-content">

        <h3>Description</h3>
        <p>{competitionDescription}</p>

        <h3>Rules</h3>
        <p>{competitionRules}</p>
        
        <h3>Prizes</h3>

        <ul className="prize-list">
          {competitionPrizeList.map((prize, index) => (
            <li key={index}>
              <b>{prize.key}:</b> <span>{prize.value}</span>
            </li>
          ))}
        </ul>

        {userSessionScope === "COMPETITOR" ? (
          <Button text="Enter Competition" onClick={onClickCreateSubmission} className="primary enter-comp-button" />
        ) : <></>}

        {userSessionScope === "OWNER" || userSessionScope === "ADMIN" || isGuestUser ? (
          <>
            <div className="share-comp-link">
              <h2>Share competition link</h2>
              <div className="comp-link" onClick={copyToClipboard} style={{cursor: "pointer"}}>
                <div className="link-text">{`${window.location.href}?redirect=true`}</div>
                <div className="comp-link-icon" />
                <div className="tooltip">Copy and share</div>
              </div>
            </div>
            <ListSubmissions competitionData={competitionData} competitionId={competitionId} competitionName={competitionName} competitionEndDate={competitionEndDate} />
          </>
        ) : <Button text="Open Leaderboard" onClick={onClickLeaderboard} className="secondary open-leaderboard-button" />}

        {(sponsors && sponsors.length > 0) ? (
          <>
            <h3>Sponsors</h3>
            <div className="sponsors-container">
            {sponsors.map((sponsor, index) => {
              const { logo_url: logoUrl, web_url: webUrl, name } = sponsor;

              if (!logoUrl) return null;

              return (
                <div key={index} className="sponsor-item">
                  {webUrl ? (
                    <a href={webUrl} target="_blank" rel="noopener noreferrer">
                      <img src={logoUrl} alt={`${name || 'Sponsor'} logo`} />
                    </a>
                  ) : (
                    <img src={logoUrl} alt={`${name || 'Sponsor'} logo`} />
                  )}
                </div>
              );
            })}
          </div>
          </>
        ) : null}

        <ListVotingPanel competitionId={competitionId} users={users} />
      </div>
    </div>
    )
  );
};

export default Competition;
